import { createContext, useContext } from 'react'
import { User } from '@ts-types/User'
import { StoreEmployee } from '@ts-types/storeEmployee'
import Provider from './Provider'
import { StoreImage } from '@ts-types/Stores'
import { StoreEmployeeImage } from '@ts-types/storeEmployeeImage'

export type TAuthState = {
  isSignedIn: boolean
  accessToken: string
  isAccessTokenRestored: boolean
  isProfileRestored: boolean
  isProfileRestoredUnsuccessfully: boolean
  profile: User
  isVerifiedToken: boolean
  whoAmILoading: boolean
}

type TAuthUtils = {
  login: () => void
  restoreToken: (accessToken: string) => void
  restoreUser: (profile: User) => void
  restoreUserFailed: () => void
  whoAmIIsLoading: (loading: boolean) => void
  addEmployedByStore: (employedByStore: StoreEmployee) => void
  replaceStoreImage: (storeId: number, storeImage?: StoreImage) => Promise<void>
  replaceStoreEmployeeImage: (storeId: number, storeEmployeeImage?: StoreEmployeeImage) => Promise<void>
  signOut: () => void
}

type TAuthAction =
  | { type: 'RESTORE_USER', payload: { profile: TAuthState['profile'] } }
  | { type: 'RESTORE_USER_FAILED' }
  | { type: 'RESTORE_TOKEN', payload: { accessToken: TAuthState['accessToken'] } }
  | { type: 'WHO_AM_I_IS_LOADING', payload: { loading: boolean } }
  | { type: 'ADD_EMPLOYED_BY_STORE', payload: { employedByStore: StoreEmployee } }
  | { type: 'REPLACE_STORE_IMAGE', payload: { storeId: number, storeImage?: StoreImage } }
  | { type: 'REPLACE_STORE_EMPLOYEE_IMAGE', payload: { storeId: number, storeEmployeeImage?: StoreEmployeeImage } }
  | { type: 'SIGN_OUT' }

type TAuthContext = TAuthState & TAuthUtils

export const stateDefault: TAuthState = {
  accessToken: '',
  isAccessTokenRestored: false,
  isSignedIn: false,
  isProfileRestored: false,
  isProfileRestoredUnsuccessfully: false,
  profile: {
    oAuthUser: null,
    employedByStores: []
  },
  isVerifiedToken: false,
  whoAmILoading: true,
}

export const reducers = (prevState: TAuthState, action: TAuthAction) => {
  switch (action.type) {
    case 'RESTORE_USER':
      return {
        ...prevState,
        isSignedIn: true,
        isVerifiedToken: true,
        profile: {
          ...prevState.profile,
          ...action.payload.profile
        },
        isProfileRestored: true,
        isProfileRestoredUnsuccessfully: false,
        whoAmILoading: false
      }
    case 'RESTORE_USER_FAILED':
      return {
        ...prevState,
        isSignedIn: false,
        isProfileRestored: false,
        isProfileRestoredUnsuccessfully: true,
        whoAmILoading: false
      }
    case 'RESTORE_TOKEN':
      return {
        ...prevState,
        isAccessTokenRestored: true,
        accessToken: action.payload.accessToken
      }
    case 'WHO_AM_I_IS_LOADING':
      return {
        ...prevState,
        whoAmILoading: action.payload.loading
      }
    case 'ADD_EMPLOYED_BY_STORE':
      return {
        ...prevState,
        profile: {
          ...prevState.profile,
          employedByStores: [
            ...prevState.profile.employedByStores,
            action.payload.employedByStore
          ]
        },
      }
    case 'REPLACE_STORE_IMAGE':
      return {
        ...prevState,
        profile: {
          ...prevState.profile,
          employedByStores: prevState.profile.employedByStores
            .map(
              (employedByStore: StoreEmployee) =>
                employedByStore.store.id === action.payload.storeId ? ({
                  ...employedByStore,
                  store: {
                    ...employedByStore.store,
                    images: action.payload.storeImage ? [
                      action.payload.storeImage,
                    ] : [],
                  }
                }) : employedByStore
            )
        },
      }
    case 'REPLACE_STORE_EMPLOYEE_IMAGE':
      return {
        ...prevState,
        profile: {
          ...prevState.profile,
          employedByStores: prevState.profile.employedByStores
            .map(
              (employedByStore: StoreEmployee) =>
                employedByStore.store.id === action.payload.storeId ? ({
                  ...employedByStore,
                  images: action.payload.storeEmployeeImage ? [
                    action.payload.storeEmployeeImage,
                  ] : [],
                }) : employedByStore
            )
        },
      }
    case 'SIGN_OUT':
      return {
        ...prevState,
        isSignedIn: false,
        isProfileRestored: true,
        isProfileRestoredUnsuccessfully: false,
        accessToken: ''
      }
  }
}

export const AuthProvider = Provider

export const AuthContext = createContext<any>({})
export default (): TAuthContext => useContext(AuthContext)
