import * as Sentry from '@sentry/gatsby'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  // tracesSampleRate: 1.0,
  environment: process.env.SENTRY_ENVIRONMENT,
  release: process.env.SENTRY_RELEASE,
  integrations: [
    // Sentry.browserTracingIntegration(),
  ],
  // tracePropagationTargets: [process.env.STORES_WEBAPP_HOST, process.env.STORES_SERVER_HOST],
});
