import React, { useReducer, useEffect, useMemo } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { navigate } from 'gatsby'
import { AuthContext, stateDefault, reducers, TAuthState } from '@hooks/useAuth'
import { User } from '@ts-types/User'
import { StoreEmployee } from '@ts-types/storeEmployee'
import getURLSearchParams from '@utils/getURLSearchParams'
import isBrowser from '@utils/isBrowser'
import { StoreImage } from '@ts-types/Stores'
import { StoreEmployeeImage } from '@ts-types/storeEmployeeImage'

const propTypes = {
  children: PropTypes.node.isRequired,
}

type Props = InferProps<typeof propTypes>

function AuthProvider({ children }: Props) {
  const [state, dispatch]: [TAuthState, any] = useReducer(reducers, stateDefault)

  const authUtils = useMemo(
    () => ({
      login: () => {
        const redirectTo: string = encodeURIComponent(window?.location.toString())
        const url = `${process.env.OAUTH_WEBAPP_HOST}?redirectTo=${redirectTo}`
        window?.location.replace(url)
      },
      restoreToken: (accessToken: string): void => {
        dispatch({ type: 'RESTORE_TOKEN', payload: { accessToken } })
      },
      restoreUser: (profile: User): void => {
        dispatch({ type: 'RESTORE_USER', payload: { profile } })
      },
      restoreUserFailed: () => {
        dispatch({ type: 'RESTORE_USER_FAILED' })
      },
      whoAmIIsLoading: (loading: boolean) => {
        dispatch({ type: 'WHO_AM_I_IS_LOADING', payload: { loading } })
      },
      addEmployedByStore: (employedByStore: StoreEmployee) => {
        dispatch({ type: 'ADD_EMPLOYED_BY_STORE', payload: { employedByStore } })
      },
      replaceStoreImage: (storeId: number, storeImage: StoreImage) => {
        dispatch({ type: 'REPLACE_STORE_IMAGE', payload: { storeId, storeImage } })
      },
      replaceStoreEmployeeImage: (storeId: number, storeEmployeeImage: StoreEmployeeImage) => {
        dispatch({ type: 'REPLACE_STORE_EMPLOYEE_IMAGE', payload: { storeId, storeEmployeeImage } })
      },
      signOut: async (): Promise<void> => {
        dispatch({ type: 'SIGN_OUT' })
      },
    }),
    [dispatch]
  )

  useEffect(() => {
    const bootstrapAsync = async (): Promise<void> => {
      if (isBrowser()) {
        const urlParams: URLSearchParams = getURLSearchParams()
        const accessTokenFromParams: string = urlParams.get('accessToken')

        if (accessTokenFromParams) {
          const url = new URL(window?.location.toString())
          url.searchParams.delete('accessToken')

          authUtils.restoreToken(accessTokenFromParams)

          const path: string = `${url.pathname}${url.search}`

          navigate(path, { replace: true })
        } else {
          authUtils.login()
        }
      }
    }

    bootstrapAsync()
  }, [])

  return <AuthContext.Provider value={{ ...state, ...authUtils }}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = propTypes

export default AuthProvider
