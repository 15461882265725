import React from 'react'
import { QueryClient, QueryClientProvider } from '@wicadu/stores-services'

import { AuthProvider } from '@hooks/useAuth'
import Theme from '@HOCs/ThemeProvider'

const THREE_MINUTES_CACHE = 1000 * 60 * 3

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: THREE_MINUTES_CACHE,
    },
  },
});

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <Theme>
      <AuthProvider>
        {element}
      </AuthProvider>
    </Theme>
  </QueryClientProvider>
)
